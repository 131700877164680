// modules
import React, { Component } from 'react';
// styles
import './Pill.scss';

class Pill extends Component {

  render () {

    const {
      type,
      classes,
      content
    } = this.props;

    return (
      <div className={"pill pill--" + type + " " + classes}>
        { content ? <span>{content}</span> : "" }
      </div>
    )

  }

}

export default Pill;

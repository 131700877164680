// modules
import React from 'react';
// components
import Nav from './components/organisms/Nav';
import Conversation from './components/organisms/Conversation';
// styles
import './scss/app.scss';

const App = () => {
  return (
    <div className="container">
      <Nav />
      <Conversation />
    </div>
  );
}

export default App;

// modules
import React, { Component } from 'react';
// components
import Pill from '../atoms/Pill';
// helpers
import { formatAMPM } from '../../helpers/formatAMPM';
// styles
import './Post.scss';

class Post extends Component {

  render () {

    const {
      authorPic,
      text,
      date,
      authorName
    } = this.props.post;

    const {
      topic,
      mood,
      activeTopics,
      activeMoods
    } = this.props;

    let newDate = new Date(date);

    // filtering
    let classes = "";
    if (activeTopics !== undefined) {
      if (activeMoods !== undefined && typeof activeMoods !== 'object') {
        if (activeTopics === topic && activeMoods === mood) {
          classes = "post--active";
        } else {
          classes = "post--inactive";
        }
      } else {
        if (activeTopics === topic) {
          classes = "post--active";
        } else {
          classes = "post--inactive";
        }
      }
    } else if (activeMoods !== undefined && typeof activeMoods !== 'object') {
      if (activeMoods === mood) {
        classes = "post--active";
      } else {
        classes = "post--inactive";
      }
    }

    return (
      <div className={"post " + classes}>
        <div className="post__header">
          <img src={authorPic} alt={authorName} className="post__image" />
          <div className="post__details">
            <h3>{authorName}</h3>
            <span>{formatAMPM(newDate)}</span>
            {
              topic ? (
                <React.Fragment>
                  <div className="flex-break"></div>
                  <Pill type="topic" classes={"pill--small"} content={topic} />
                </React.Fragment> 
              ) : ''
            }
          </div>
        </div>
        <p className="post__text">{text}</p>
        {
          mood ? <Pill type="mood" classes={"pill--" + mood} content="" /> : ''
        }
      </div>
    )

  }

}

export default Post;

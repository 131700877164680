export function getMoodString(tag, object) {

  for (let [key, value] of Object.entries(object)) {
    if (JSON.stringify(tag) === key) {
      return value.toLowerCase();
    }
  }

}

export function getTopicString(tag, object) {

  for (let [key, value] of Object.entries(object)) {
    if (tag === key) {
      return value;
    }
  }

}
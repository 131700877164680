// modules
import React, { Component } from 'react';
// styles
import './Mood.scss';

class Mood extends Component {

  render () {

    const {
      percentage,
      dash,
      mood,
      count,
      posts
    } = this.props;

    return (
      <div className={"mood mood--" + mood}>
        <div className="mood__percentage">{percentage + "%"}</div>
        <svg viewBox="0 0 100 100" className="sentiment-percentage">
          <circle className="full" cx="50%" cy="50%" r="42%"></circle>
          <circle className="percentage" cx="50%" cy="50%" r="42%" style={{ strokeDashoffset: dash }}></circle>
        </svg>
        <small>{count + '/' + posts.length}</small>
      </div>
    )

  }

}

export default Mood;

// modules
import React from 'react';
// assets
import Logo from '../../assets/logo.svg';
// styles
import './Nav.scss';

const Nav = () => {
  return (
    <div className="navigation">
      <img className="logo" src={Logo} alt="Ketchup" />
      <nav className="menu menu--main">
        <ul>
          <li><a href="/">Dashboard</a></li>
          <li><a href="/">Conversations</a></li>
          <li><a href="/">Current Conversation</a></li>
        </ul>
      </nav>
      <nav className="menu menu--user">
        <ul>
          <li><a href="/">Inbox</a></li>
          <li><a href="/">Mail</a></li>
          <li><a href="/">Print</a></li>
          <li><a href="/">Settings</a></li>
          <li><a href="/">Info</a></li>
        </ul>
      </nav>
      <a href="/" className="profile">AB</a>
    </div>
  );
}

export default Nav;

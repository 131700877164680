export function formatAMPM(date) {

  let day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hours = date.getHours(),
      minutes = date.getMinutes();
  
  let ampm = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  
  return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ' ' + ampm;

}
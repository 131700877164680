// modules
import React, { Component } from 'react';
// components
import Pill from '../atoms/Pill';
import Mood from '../atoms/Mood';
import Post from '../molecules/Post';
// helpers
import { getMoodString, getTopicString } from '../../helpers/getTagString';
// data
import ConversationData from '../../data/posts.json';
// styles
import './Conversation.scss';

class Conversation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      moods: ConversationData.moods,
      topics: ConversationData.topics,
      posts: ConversationData.posts,
      activeTopics: ConversationData.topics,
      activeMoods: ConversationData.moods,
      focusTopicPill: "All",
      focusMoodPill: "All"
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 4000);
  }

  countMood(value) {
    const filteredMood = this.state.posts.filter(post => post.mood === value);
    return filteredMood.length;
  }

  compareMoods() {
    let highest,
        sad = this.countMood(0),
        meh = this.countMood(1),
        happy = this.countMood(2);

    if (sad > meh && sad > happy) {
      highest = "sad";
    } else if (meh > sad && meh > happy) {
      highest = "meh";
    } else {
      highest = "happy";
    }

    return highest;
  }

  calcPercentage(value) {
    const total = this.state.posts.length;
    return ((value / total) * 100).toFixed(2);
  }

  calcDashOffset(value) {
    const increment = 270 / 100;
    return 270 - (this.calcPercentage(value) * increment);
  }

  handleTopicFilter = (e) => {
    if (e === "All") {
      this.setState({
        activeTopics: this.state.topics,
        focusTopicPill: "All"
      });
    } else {
      this.setState({
        activeTopics: e,
        focusTopicPill: e
      });
    }
  }

  handleMoodFilter = (e) => {
    if (e === "All") {
      this.setState({
        activeMoods: this.state.moods,
        focusMoodPill: "All"
      });
    } else {
      this.setState({
        activeMoods: e,
        focusMoodPill: e
      });
    }
  }

  render () {

    const {
      isLoading,
      posts,
      moods,
      topics,
      activeTopics,
      activeMoods,
      focusTopicPill,
      focusMoodPill
    } = this.state;

    return (
      <React.Fragment>
        <nav className="highlight-bar">
          <div className="highlight-bar__left">
            <h6>Highlight topic:</h6>
            <ul className="pill-list">
              <li className="pill-list__item" onClick={() => this.handleTopicFilter("All")}>
                <Pill
                  type="topic"
                  classes={"pill--highlight" + (focusTopicPill === "All" ? " pill--active" : "")}
                  content={"All"}
                />
              </li>
              {
                Object.keys(topics).map((topic, i) => {
                  return (
                    <li key={i} className="pill-list__item" onClick={() => this.handleTopicFilter(topic)}>
                      <Pill
                        type="topic"
                        classes={"pill--highlight" + (focusTopicPill === topic || focusTopicPill === "All" ? " pill--active" : "")}
                        content={getTopicString(topic, topics)}
                      />
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="highlight-bar__right">
            <h6>Highlight mood:</h6>
            <ul className="pill-list">
              <li className="pill-list__item" onClick={() => this.handleMoodFilter("All")}>
                <Pill
                  type="topic"
                  classes={"pill--highlight" + (focusMoodPill === "All" ? " pill--active" : "")}
                  content={"All"}
                />
              </li>
              {
                Object.keys(moods).map((mood, i) => {
                  return (
                    <li key={i} className="pill-list__item" onClick={() => this.handleMoodFilter(getMoodString(i, moods))}>
                      <Pill
                        type="mood"
                        classes={"pill--highlight pill--icon pill--" + getMoodString(i, moods) + (focusMoodPill === getMoodString(i, moods) || focusMoodPill === "All" ? " pill--active" : "")}
                        content=" "
                      />
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </nav>
        <main>
          {
            isLoading ? (
              <div className="page-loader"></div>
            ) : (
              <ul className="post-list">
                { // get the initial mention
                  posts.filter(post => post.replyToId === null).map((post, i) => {
                    return (
                      <li key={i} className="post-list__item post-list__item--1">
                        <Post
                          post={post}
                          mood={getMoodString(post.mood, moods)}
                          topic={getTopicString(post.topic, topics)}
                          activeTopics={getTopicString(activeTopics, topics)}
                          activeMoods={activeMoods}
                        />
                        <ul>
                          { // get the direct replies
                            posts.filter(replyOne => replyOne.replyToId === post.id).map((replyOne, ii) => {
                              return (
                                <li key={ii} className="post-list__item post-list__item--2">
                                  <Post
                                    post={replyOne}
                                    mood={getMoodString(replyOne.mood, moods)}
                                    topic={getTopicString(replyOne.topic, topics)}
                                    activeTopics={getTopicString(activeTopics, topics)}
                                    activeMoods={activeMoods}
                                  />
                                  <ul>
                                    { // get the collatoral replies
                                      posts.filter(replyTwo => replyTwo.replyToId === replyOne.id).map((replyTwo, iii) => {
                                        return (
                                          <li key={iii} className="post-list__item post-list__item--3">
                                            <Post
                                              post={replyTwo}
                                              mood={getMoodString(replyTwo.mood, moods)}
                                              topic={getTopicString(replyTwo.topic, topics)}
                                              activeTopics={getTopicString(activeTopics, topics)}
                                              activeMoods={activeMoods}
                                            />
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </li>
                    )
                  })
                }
              </ul>
            )
          }
        </main>
        <aside>
          <h4>Conversation Sentiments</h4>
          <h6>Overall mood of {posts.length} posts:</h6>
          <Pill
            type="mood"
            classes={"pill--" + this.compareMoods()}
            content={"The majority of posts are " + this.compareMoods() + "."}
          />
          <h6>Mood breakdown:</h6>
          <ul className="mood-list">
            { // get the moods
              Object.keys(moods).map((mood, i) => {
                return (
                  <li key={i} className="mood-list__item">
                    <Mood
                      mood={getMoodString(i, moods)}
                      posts={posts}
                      dash={this.calcDashOffset(this.countMood(i))}
                      percentage={this.calcPercentage(this.countMood(i))}
                      count={this.countMood(i)}
                    />
                  </li>
                )
              })
            }
          </ul>
        </aside>
      </React.Fragment>
    )

  }

}

export default Conversation;
